// Copied from https://github.com/s-yadav/react-number-format/issues/422#issuecomment-925363395

import { forwardRef, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { SprInputTooltip, SprInputTooltipProps } from './SprInput';

export type CurrencyTextFieldProps = SprInputTooltipProps &
  Omit<NumericFormatProps, 'size' | 'customInput'> &
  Omit<TextFieldProps, 'value' | 'defaultValue' | 'type'>;

// this is needed to please TypeScript as it has a conflict with the `size` prop
function TextFieldMiddleware(params: TextFieldProps & { textFieldSize?: 'small' | 'medium' }) {
  const { textFieldSize, ...textFieldProps } = params;
  return <TextField {...textFieldProps} size={textFieldSize} />;
}

export const CurrencyTextField = forwardRef((props: CurrencyTextFieldProps, ref) => {
  const {
    size,
    tooltipVisibility = 'onFocus',
    error,
    helperText,
    onFocus,
    onBlur,
    tooltipProps,
    value,
    ...textFieldProps
  } = props;

  const [hasFocus, setHasFocus] = useState(false);

  const isVisible = error || tooltipVisibility === 'always' || (tooltipVisibility === 'onFocus' && hasFocus);

  const localOnFocus: TextFieldProps['onFocus'] = (event) => {
    setHasFocus(true);
    onFocus?.(event);
  };
  const localOnBlur: TextFieldProps['onBlur'] = (event) => {
    setHasFocus(false);
    onBlur?.(event);
  };

  return (
    <SprInputTooltip arrow open={isVisible} title={helperText} hasError={error} {...tooltipProps}>
      {/* wrapper Box needed for the tooltip */}
      <Box sx={textFieldProps.style}>
        <NumericFormat
          value={value}
          valueIsNumericString
          decimalScale={2}
          fixedDecimalScale={typeof value === 'number' && value % 1 != 0}
          thousandSeparator="."
          decimalSeparator=","
          inputRef={ref}
          customInput={TextFieldMiddleware}
          // map the size prop to textFieldSize so it can be passed correctly to the TextFieldMiddleware component
          textFieldSize={size}
          // MUI TextField props
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          error={error}
          onFocus={localOnFocus}
          onBlur={localOnBlur}
          {...textFieldProps}
        />
      </Box>
    </SprInputTooltip>
  );
});

CurrencyTextField.displayName = 'CurrencyTextField';
