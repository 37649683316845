// interpret UTC date as same date local time
export function toDate(date: Date | string) {
  date = new Date(date);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function getTimezoneOffset(date: Date) {
  return date.getTimezoneOffset() * 60000;
}

export function makeLocalAppearUTC(date: Date | null) {
  if (!date) return date;
  const dateTime = new Date(date);
  const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  return utcFromLocal;
}

export function localToUTC(dateTime: Date | null) {
  if (!dateTime) return dateTime;
  const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
  return utcFromLocal;
}
