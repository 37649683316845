import { mergeWith } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { CurrencyTextField, CurrencyTextFieldProps } from './CurrencyTextField';

type RHFCurrencyTextFieldProps = { name: string } & CurrencyTextFieldProps;

export function RHFCurrencyTextField({
  name,
  onValueChange,
  helperText,
  slotProps,
  ...other
}: RHFCurrencyTextFieldProps) {
  const { control } = useFormContext();
  const mergedSlotProps = mergeWith(slotProps, { htmlInput: { style: { textAlign: 'right' } } });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restOfField }, fieldState: { error } }) => (
        <CurrencyTextField
          {...restOfField}
          error={!!error}
          helperText={error?.message ?? helperText}
          value={restOfField.value ?? ''}
          onValueChange={({ value, floatValue, formattedValue }, sourceInfo) => {
            // don't update on prop change
            if (sourceInfo.source === 'event') {
              const localValue = value === '' ? undefined : floatValue; // prevent NaN error message
              onChange({ target: { name, value: localValue } });
              onValueChange?.({ value, floatValue, formattedValue }, sourceInfo);
            }
          }}
          slotProps={mergedSlotProps}
          {...other}
        />
      )}
    />
  );
}
