import { forwardRef } from 'react';
import { BoxProps } from '@mui/material';
import { Image } from 'src/components/Image';
import { PATH_APP } from 'src/paths';
import { Link } from './Link';

interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

export const Logo = forwardRef<any, LogoProps>(({ disabledLink = false, sx }, ref) => {
  const logo = (
    <Image ref={ref} src="/logo/SponsoRights_Logo.png" alt="logo" sx={{ height: '32px', ...sx }} />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link href={PATH_APP.root} noWrap>
      {logo}
    </Link>
  );
});

Logo.displayName = 'Logo';
