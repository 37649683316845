import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

interface RHFToggleButtonGroupProps extends ToggleButtonGroupProps {
  name: string;
  options: {
    label: string;
    value: any;
  }[];
}

export function RHFToggleButtonGroup({ name, options, ...other }: RHFToggleButtonGroupProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <ToggleButtonGroup exclusive {...field} {...other}>
            {options.map((option) => (
              <ToggleButton key={option.value} value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
