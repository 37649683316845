import { Box, IconButton } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { localToUTC, makeLocalAppearUTC } from 'src/utils/compareTime';
import { Iconify } from '../Iconify';
import { SprInput, SprInputProps } from './SprInput';

interface TFProps {
  helperText?: SprInputProps['helperText'];
  textFieldProps?: SprInputProps;
  clearable?: boolean;
}

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

export type UtcDatePickerProps = TFProps & Optional<DatePickerProps<Date, Date>, 'renderInput' | 'onChange'>;

export function UtcDatePicker({
  value,
  onChange,
  renderInput,
  helperText,
  textFieldProps,
  clearable,
  mask = '__.__.__',
  ...rest
}: UtcDatePickerProps) {
  if (!renderInput) {
    const isClearable = clearable && value && !(rest.disabled || rest.readOnly);

    if (isClearable) {
      renderInput = (params) => (
        <Box position="relative" display="inline-block">
          <SprInput helperText={helperText} {...textFieldProps} {...params} />
          <IconButton
            style={{ position: 'absolute', top: '.5rem', margin: 'auto', right: '2rem' }}
            onClick={() => onChange?.(null)}
          >
            <Iconify icon="eva:close-outline" width={24} height={24} />
          </IconButton>
        </Box>
      );
    }
    // is not clearable
    else {
      renderInput = (params) => <SprInput helperText={helperText} {...params} {...textFieldProps} />;
    }
  }

  const inputPattern = new RegExp(`^${mask.replace(/_/g, '\\d').replace(/\./g, '\\.')}$`); // '__.__.' -> /^\d\d\.\d\d\.$/

  return (
    <DatePicker
      value={makeLocalAppearUTC(value) ?? null}
      onChange={(newDate, inputValue) => {
        if (inputValue != null && !inputPattern.test(inputValue)) {
          onChange?.(new Date(NaN));
        } else {
          onChange?.(localToUTC(newDate));
        }
      }}
      renderInput={renderInput}
      inputFormat="dd.MM.yy"
      mask={mask}
      {...rest}
    />
  );
}
