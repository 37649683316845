import { Controller, useFormContext } from 'react-hook-form';
import { UtcDatePicker, UtcDatePickerProps } from './UtcDatePicker';

type RHFDatePickerProps = {
  name: string;
  onChange?: UtcDatePickerProps['onChange'];
} & Omit<UtcDatePickerProps, 'value' | 'onChange' | 'renderInput'>;

export function RHFDatePicker({
  name,
  onChange,
  onClose,
  helperText,
  textFieldProps,
  ...pickerProps
}: RHFDatePickerProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, onChange: fieldOnChange, onBlur: fieldOnBlur, ...otherFieldProps },
        fieldState: { error },
      }) => (
        <UtcDatePicker
          {...pickerProps}
          {...otherFieldProps}
          value={value ?? ''}
          inputRef={ref}
          onClose={(...args) => {
            fieldOnBlur();
            onClose?.(...args);
          }}
          onChange={(newDate, keyboardInputValue) => {
            fieldOnChange(newDate);
            onChange?.(newDate, keyboardInputValue);
          }}
          textFieldProps={{
            ...textFieldProps,
            onBlur(event) {
              fieldOnBlur();
              textFieldProps?.onBlur?.(event);
            },
            error: !!error,
            helperText: error?.message ?? helperText ?? textFieldProps?.helperText,
          }}
        />
      )}
    />
  );
}
