import { ReactNode } from 'react';
import { keyBy, mapValues } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from '@mui/material';

interface RHFMultiSelectProps {
  name: string;
  options: { key: string; label: string }[];
  fullWidth?: boolean;
  helperText?: ReactNode;
}

export function RHFMultiSelect({
  name,
  label,
  required,
  options,
  fullWidth,
  helperText,
  ...other
}: RHFMultiSelectProps & SelectProps) {
  const { control } = useFormContext();
  const optionsMap = mapValues(keyBy(options, 'key'), 'label');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...otherFieldProps }, fieldState: { error } }) => (
        <FormControl>
          {label && (
            <InputLabel error={!!error} required={required} id={`multi-select-${name}`}>
              {label}
            </InputLabel>
          )}

          <Select
            {...other}
            labelId={`multi-select-${name}`}
            multiple
            inputRef={ref}
            value={value ?? []}
            error={!!error}
            label={label || undefined}
            required={required}
            fullWidth={fullWidth != null ? fullWidth : true}
            renderValue={(selected) =>
              selected
                .map((key: any) => optionsMap[key])
                .filter(Boolean)
                .join(', ')
            }
            input={<OutlinedInput label={label} />}
            {...otherFieldProps}
          >
            {options?.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                <Checkbox checked={Array.isArray(value) ? value.includes(option.key) : false} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>

          {(error || helperText) && (
            <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
