import { ReactNode } from 'react';
import { Control, Controller, ControllerProps, FieldError, Path, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';

export interface AutocompleteElementProps<
  F extends FieldValues,
  T,
  M extends boolean | undefined,
  D extends boolean | undefined,
> {
  name: Path<F>;
  control?: Control<F>;
  options: T[];
  loading?: boolean;
  multiple?: M;
  matchId?: boolean;
  rules?: ControllerProps['rules'];
  parseError?: (error: FieldError) => ReactNode;
  required?: boolean;
  label?: TextFieldProps['label'];
  showCheckbox?: boolean;
  autocompleteProps?: Omit<AutocompleteProps<T, M, D, any>, 'name' | 'options' | 'loading' | 'renderInput'>;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
}

interface AutoDefault {
  id: string | number; // must keep id in case of keepObject
  label: string;
}

export function RHFAutocomplete<TFieldValues extends FieldValues>({
  textFieldProps,
  autocompleteProps,
  name,
  options,
  loading,
  showCheckbox,
  rules,
  required,
  multiple,
  matchId,
  label,
}: AutocompleteElementProps<
  TFieldValues,
  AutoDefault | string | any,
  boolean | undefined,
  boolean | undefined
>) {
  const { control } = useFormContext();

  const validationRules: ControllerProps['rules'] = {
    ...rules,
    ...(required && {
      required: rules?.required || 'This field is required',
    }),
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        let currentValue = multiple ? value || [] : (value ?? null);
        if (matchId) {
          currentValue = multiple
            ? (value || []).map((i: any) => options.find((j) => (j.id ?? j) === i))
            : (options.find((i) => (i.id ?? i) === value) ?? null);
        }
        return (
          <Autocomplete
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            disableCloseOnSelect={
              typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
                ? autocompleteProps.disableCloseOnSelect
                : !!multiple
            }
            isOptionEqualToValue={
              autocompleteProps?.isOptionEqualToValue
                ? autocompleteProps.isOptionEqualToValue
                : (option, value) => (value ? option.id === (value?.id ?? value) : false)
            }
            getOptionLabel={
              autocompleteProps?.getOptionLabel
                ? autocompleteProps.getOptionLabel
                : (option) => `${option?.name ?? option}`
            }
            onChange={(event, value, reason, details) => {
              let changedVal = value;
              if (matchId) {
                changedVal = Array.isArray(value) ? value.map((i: any) => i?.id ?? i) : (value?.id ?? value);
              }
              onChange(changedVal);
              if (autocompleteProps?.onChange) {
                autocompleteProps.onChange(event, value, reason, details);
              }
            }}
            renderOption={
              autocompleteProps?.renderOption ??
              (showCheckbox
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                      {autocompleteProps?.getOptionLabel?.(option) || option.name || option}
                    </li>
                  )
                : undefined)
            }
            onBlur={(event) => {
              onBlur();
              if (typeof autocompleteProps?.onBlur === 'function') {
                autocompleteProps.onBlur(event);
              }
            }}
            renderInput={(params) => (
              <TextField
                name={name}
                required={rules?.required ? true : required}
                label={label}
                {...textFieldProps}
                {...params}
                error={!!error}
                helperText={error ? error.message : textFieldProps?.helperText}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    ...textFieldProps?.slotProps?.input,
                  },

                  htmlInput: {
                    ...params.inputProps,
                    ...textFieldProps?.slotProps?.htmlInput,
                  },

                  inputLabel: {
                    ...params.InputLabelProps,
                    ...textFieldProps?.slotProps?.inputLabel,
                  },
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
