import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';

interface RHFSwitchProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
}

export function RHFSwitch({ name, ...other }: RHFSwitchProps) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Switch {...field} checked={!!field.value} />}
        />
      }
      {...other}
    />
  );
}
